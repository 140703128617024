import React from 'react';
import ReactMapboxGl,{Marker} from '!react-mapbox-gl';
import {ReactMapboxGlCluster} from '!react-mapbox-gl-cluster';
import equal from 'fast-deep-equal';

const Map = ReactMapboxGl({
    accessToken: process.env.MAP_KEY,
});

const changeMapLanguage = (map) => {
    map.getStyle().layers.forEach((layer) => {
        if (layer.id.endsWith('-label')) {
            map.setLayoutProperty(layer.id, 'text-field', [
                'coalesce',
                ['get', 'name_ua'],
                ['get', 'name'],
            ]);
        }
    });
};

const mapProps = {
    center: [33.36212, 47.884434],
    zoom: [5],
    style: 'mapbox://styles/mapbox/streets-v11',
};

export default class MapInterface extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            geojson: null,
            minLng: null,
            maxLng: null,
            minLat: null,
            maxLat: null,
            isMobile: false,
        };
    }

    getEventHandlers() {
        return {
          onClick: (properties) => this.props.loadObject(properties.id)
        };
    }

    componentDidMount() {
        this.setState({
            isMobile: window.innerWidth > 767 ? false : true
        });

        function clearActiveDots(){
            let activeElms = document.querySelectorAll('.mapboxgl-canvas-container .mapboxgl-marker .cluster-layer--point.is-active');
            activeElms.forEach(el => {
                el.classList.remove('is-active');
            })
        }

        document.addEventListener('click',function(e){
            if(e.target && e.target.classList.contains("cluster-layer--point")){
                clearActiveDots();
                e.target.classList.add('is-active');
             }
         });
        document.addEventListener('click',function(e){
            if(e.target && e.target.classList.contains("view-map__results__back")){
                clearActiveDots();
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.points, prevProps.points)) {
            const points = {
                type: 'FeatureCollection',
                features: [],
            };
            const pointsData = this.props.points;
            
            if(pointsData !== null) {
                let minLng = pointsData[0].lng;
                let maxLng = pointsData[0].lng;
                let minLat = pointsData[0].lat;
                let maxLat = pointsData[0].lat;
                
                
                points.features = pointsData.map((crime, index) => {
                    for (let i = 0; i < pointsData.length; i++) {
                        if (i === index) continue;
                        if (parseFloat(pointsData[i].lng) === parseFloat(crime.lng) 
                            && parseFloat(pointsData[i].lat) === parseFloat(crime.lat)) {
                            crime.lng = crime.lng + Math.random() * (0.0007 - 0.0001) + 0.0001;
                            crime.lat = crime.lat + Math.random() * (0.0007 - 0.0001) + 0.0001;
                        }
                    }

                    minLng = crime.lng < minLng ? crime.lng : minLng;
                    maxLng = crime.lng > maxLng ? crime.lng : maxLng;
                    minLat = crime.lat < minLat ? crime.lat : minLat;
                    maxLat = crime.lat > maxLat ? crime.lat : maxLat;

                    return {
                        type: 'Feature',
                        properties: {
                            id: crime.object_id
                        },
                        geometry: {
                        type: 'Point',
                            coordinates: [
                                parseFloat(crime.lng),
                                parseFloat(crime.lat)
                            ]
                        }
                    }
                });

                setTimeout(() => {
                    this.setState({
                        geojson: points,
                        minLng: minLng,
                        maxLng: maxLng,
                        minLat: minLat,
                        maxLat: maxLat
                    });
                    this.forceUpdate();
                }, 100)
            } else {
                setTimeout(() => {
                    this.setState({
                        geojson: null
                    });
                    this.forceUpdate();
                }, 100)
            }
        }
    }

    render() {
        return(
            <div className={`view-map__interface ${this.props.className}`}>
                <Map 
                    {...mapProps} 
                    containerStyle={{
                        height: this.state.isMobile ? 'calc(100vh - 44.8px)' : '100vh',
                        width: '100%'
                    }}
                    onStyleLoad={changeMapLanguage}
                    fitBounds={this.state.minLng && [[this.state.minLng, this.state.minLat], [this.state.maxLng, this.state.maxLat]]}
                    fitBoundsOptions={{
                        maxZoom: 12,
                        padding: 50
                    }}
                >
                    {this.state.geojson && (
                        <ReactMapboxGlCluster maxZoom={12} data={this.state.geojson} {...this.getEventHandlers()} />
                    )}
                </Map>
            </div>
        );
    }
}