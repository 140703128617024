import React from 'react';
import equal from 'fast-deep-equal';
import { Link } from 'gatsby';
import MapAsideForm from './MapAsideForm';
import Loader from '../../images/loader.svg';

export default class MapAside extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            objects: null,
            page: 1,
            isSingle: false,
            showLoader: !this.props.emptyResponse
        }
    }

    componentDidMount() {
        this.setState({
            showLoader: !this.props.emptyResponse
        });
        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps) {
      if (!equal(this.props.emptyResponse, prevProps.emptyResponse)) {
        this.setState({
            showLoader: !this.props.emptyResponse
        });
      }
      if (!equal(this.props.objects, prevProps.objects)) {
        this.setState({
            objects: this.props.objects,
            page: this.props.page,
            totalPages: this.props.totalPages,
            isSingle: this.props.isSingle,
            showLoader: !this.props.emptyResponse
        });
      }
      if (!equal(this.props.page, prevProps.page)) {
        window.scrollTo(0, 0)
      }
    }

    render() {
        return (
            <div className="view-map__aside">
                <h1 className="view-title view-title--h1 view-map__aside__title">
                    Об'єкти і договори <span>Фонду Держмайна України</span>
                </h1>
                <MapAsideForm 
                    params={this.props.params}
                    changeParams={this.props.changeParams}
                    filters={this.props.filters}
                />
                <div className="view-map__results">
                    {this.state.objects && this.state.objects.map((object, key) => (
                        <div key={key} className="view-map__results__item">
                            <div className="view-map__results__title">
                                {object.object_name}
                            </div>
                            <div className="view-map__results__caption view-text">
                                <p>
                                    <b>Балансоутримувач:</b> {object.owner_name}
                                </p>
                                <p>
                                    <b>Корупційні ризики: </b> 
                                    {this.props.filters.map(item => object[item.value] != 0 && item.title + '; ')}
                                </p>
                            </div>
                            <Link to={'/object?id=' + object.object_id} className="button button--link">Показати статистику</Link>
                        </div>
                    ))}
                    {(this.state.objects && this.state.isSingle ) && 
                        <div onClick={() => {this.props.backToFullList()}} className="view-map__results__back">
                            <svg width="35" height="11" viewBox="0 0 35 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.18937 0.621086L6.74838 2.18017L4.22061 4.70793L35 4.70794L35 6.91284L4.22061 6.91283L6.74837 9.44067L5.18937 10.9998L-1.36099e-06 5.81038L5.18937 0.621086Z" fill="#1A1F71"/>
                            </svg>
                            Назад до списку
                        </div>
                    }
                    {!this.state.objects && this.state.showLoader && (
                        <div className="view-loader">
                            <Loader />
                        </div>
                    )}

                    {!this.state.objects && !this.state.showLoader && (
                        <div style={{marginTop: "1.11rem", textAlign: "center"}}>
                            На жаль, по вашому запиту нічого не знайдено, спробуйте інші фільтри
                        </div>
                    )}
                </div>
                {(this.state.objects && this.state.totalPages != 1 && this.state.isSingle == false) && (
                    <div className="view-pagination">
                        <a 
                            href={`#page=${this.state.page - 1}`} 
                            className="view-pagination__arrow"
                            onClick={() => this.props.pagination(this.state.page - 1)} 
                            disabled={this.state.page == 1}>
                            <svg width="35" height="11" viewBox="0 0 35 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.18937 0.310547L6.74838 1.86963L4.22061 4.39739H35V6.60229H4.22061L6.74838 9.13013L5.18937 10.6892L0 5.49984L5.18937 0.310547Z" fill="#222222"/>
                            </svg>
                        </a>
                        <div className="view-pagination__list">
                            {[...Array(this.state.totalPages)].map((q, i) => (
                                <a 
                                    href={`#page=${i + 1}`} key={i} 
                                    className={`view-pagination__item ${(i + 1) == this.state.page ? 'is-active' : ''}`}
                                    onClick={() => {
                                        this.props.pagination(i + 1)
                                        this.setState({
                                            page: i + 1
                                        })
                                    }} 
                                >
                                    {i + 1}
                                </a>
                            ))}
                        </div>
                        <a 
                            href={`#page=${this.state.page + 1}`} 
                            className="view-pagination__arrow"
                            onClick={() => this.props.pagination(this.state.page + 1)} 
                            disabled={this.state.page == this.state.totalPages}
                        >
                            <svg width="35" height="11" viewBox="0 0 35 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.8106 10.6895L28.2516 9.13038L30.7794 6.60261L7.14567e-07 6.60261L1.10008e-06 4.39771L30.7794 4.39772L28.2516 1.86988L29.8106 0.310793L35 5.50016L29.8106 10.6895Z" fill="#222222"/>
                            </svg>
                        </a>
                    </div>
                )}
            </div>
        );
    }
}