import React from 'react';
import MapAside from './MapAside';
import MapInterface from './MapInterface';
import axios from 'axios';
import { navigate } from 'gatsby';

import './styles.scss';

export default class MapView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: [
                {
                    title: "Є потенційна недоплата",
                    value: "is_expected_2_plus_times_gr_than_payment",
                }, { 
                    title: "Некоректний код ЄДРПОУ орендаря",
                    value: "is_tennant_code_bug", 
                }, {
                    title: "Некоректний код ЄДРПОУ балансоутримувача",
                    value: "is_owner_code_bug",
                }, {
                    title: "Ризик заниженої оціночної вартості",
                    value: "is_less_avg_price_m2",
                }, {
                    title: "Є уточнення до ціни",
                    value: "is_price_footnotes",
                }, {
                    title: "Пільгова ставка для бізнесу",
                    value: "is_tennant_business_with_cheap_rent"
                }, {
                    title: "Оренда бізнесом під потреби ГО",
                    value: "is_tennant_ngo_business"
                }, {
                    title: "Тривале подовження у 2019-2020 рр.",
                    value: "is_big_longations_after_2019"
                }, {
                    title: "Багато ГО за однією адресою",
                    value: "is_more_than_one_ngo_agreement_per_object"
                }
            ],
            urlParams: {
                region: 'Київська',
                risks: '',
                typeOrendar: '',
                rentRate: '',
                object_size: '',
                cost: '',
                purpose: '',
                rentText: '',
            },
            objects: null,
            objectsPagi: null,
            emptyResponse: false,
            page: 1,
            totalPages: 0,
            points: null,
            mPanel: false,
            isSingle: false,
        };

        this.mPanel = React.createRef();
        this.changeParams = this.changeParams.bind(this);
        this.paginationObjects = this.paginationObjects.bind(this);
        this.loadObject = this.loadObject.bind(this);
        this.backToFullList = this.backToFullList.bind(this);
        this.getFiltersValue = this.getFiltersValue.bind(this);
    }

    paginationObjects(page = 0) {
        if (this.state.page == page || !this.state.objects) return;
        this.setState((state) => ({
            page: page
        }));
        this.loadObjects(page)
    }

    loadDots() {
        const tRegion = this.state.urlParams.region ? this.state.urlParams.region : 'Київська';
        const tRisk = this.state.urlParams.risks ? '&risks=' + this.state.urlParams.risks.join(',') : '';
        const tTypeOrendar = this.state.urlParams.typeOrendar ? '&type_orendar=' + this.state.urlParams.typeOrendar.join(',') : '';
        const tRentRate = this.state.urlParams.rentRate ? '&rent_rate=' + this.state.urlParams.rentRate: '';
        const tSize = this.state.urlParams.object_size ? '&object_size=' + this.state.urlParams.object_size: '';
        const tCost = this.state.urlParams.cost ? '&cost=' + this.state.urlParams.cost: '';
        const tPurpose = this.state.urlParams.purpose ? '&purpose=' + this.state.urlParams.purpose.join(',') : '';
        const tRentText = this.state.urlParams.rentText ? '&rent_text=' + this.state.urlParams.rentText.join(',') : '';

        axios.get(`${process.env.API_ENDPOINT}/estate/objects/dots?region=${tRegion}${tRisk}${tTypeOrendar}${tRentRate}${tSize}${tCost}${tPurpose}${tRentText}`)
            .then((response) => {
                this.setState({
                    points: response.data.length ? response.data : null
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    loadObjects(newPage = 0) {
        this.setState({
            objects: null
        });
        let page = 0;
        if (newPage == 0) {
            page = window.location.hash ? window.location.hash.split('=')[1] - 1 : 0;
        } else {
            page = newPage - 1
        }

        const tRegion = this.state.urlParams.region ? this.state.urlParams.region : 'Київська';
        const tRisk = this.state.urlParams.risks ? '&risks=' + this.state.urlParams.risks.join(',') : '';
        const tTypeOrendar = this.state.urlParams.typeOrendar ? '&type_orendar=' + this.state.urlParams.typeOrendar.join(',') : '';
        const tRentRate = this.state.urlParams.rentRate ? '&rent_rate=' + this.state.urlParams.rentRate: '';
        const tSize = this.state.urlParams.object_size ? '&object_size=' + this.state.urlParams.object_size: '';
        const tCost = this.state.urlParams.cost ? '&cost=' + this.state.urlParams.cost: '';
        const tPurpose = this.state.urlParams.purpose ? '&purpose=' + this.state.urlParams.purpose.join(',') : '';
        const tRentText = this.state.urlParams.rentText ? '&rent_text=' + this.state.urlParams.rentText.join(',') : '';

        if(page > 0) {
            page = '&page=' + page
        } else {
            page = ''
        }

        axios.get(`${process.env.API_ENDPOINT}/estate/objects/?region=${tRegion}${page}${tRisk}${tTypeOrendar}${tRentRate}${tSize}${tCost}${tPurpose}${tRentText}`)
            .then((response) => {
                this.setState({
                    objects: response.data.items.length ? response.data.items : null,
                    emptyResponse: response.data.items.length ? false : true,
                    page: Number(response.data.page) + 1,
                    totalPages: Math.ceil(response.data.total / response.data.size),
                    objectsPagi: response.data.items.length ? response.data.items : null
                });
            })
            .catch((error) => {
                console.error(error);
            });
            this.loadDots()
    }

    loadObject(id) {
        axios.get(`${process.env.API_ENDPOINT}/estate/objects/${id}`)
            .then((response) => {
                this.setState({
                    objects: response.data.length ? response.data : null,
                    page: 1,
                    totalPages: 0,
                    emptyResponse: response.data.length ? false : true,
                    objectsPagi: response.data.length ? response.data : null,
                    mPanel: false,
                    isSingle: true
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    getUrlParams() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let risks = [];

        if (urlParams.get('risks')) {
            urlParams.get('risks').split(',').forEach(item => {
                this.state.filters.forEach(filter => {
                    if (filter.value == item) {
                        risks.push(item);
                    }
                });
            });
        }

        this.setState({
            mPanel: false,
            isSingle: false,
            objects: null,
            urlParams: {
                region: urlParams.get('region') ? urlParams.get('region') : '',
                risks: risks.length ? risks : '',
                typeOrendar: urlParams.get('type_orendar') ? urlParams.get('type_orendar').split(',') : '',
                rentRate: urlParams.get('rent_rate') ? urlParams.get('rent_rate') : '',
                object_size: urlParams.get('object_size') ? urlParams.get('object_size') : '',
                cost: urlParams.get('cost') ? urlParams.get('cost') : '',
                purpose: urlParams.get('purpose') ? urlParams.get('purpose').split(',') : '',
                rentText: urlParams.get('rent_text') ? urlParams.get('rent_text').split(',') : '',
            }
        }, () => {
            this.loadObjects();
        });
    }

    changeParams({
        region = this.state.urlParams.region,
        risks = this.state.urlParams.risks,
        typeOrendar = this.state.urlParams.typeOrendar,
        rentRate = this.state.urlParams.rentRate,
        object_size = this.state.urlParams.object_size,
        cost = this.state.urlParams.cost,
        purpose = this.state.urlParams.purpose,
        rentText = this.state.urlParams.rentText,
        id = null
    }) {
        const tRisk = risks ? '&risks=' + risks.join(',') : '';
        const tId = id ? '&id=' + id : '';
        const tTypeOrendar = typeOrendar ? '&type_orendar=' + typeOrendar.join(',') : '';
        const tRentRate = rentRate ? '&rent_rate=' + rentRate: '';
        const tSize = object_size ? '&object_size=' + object_size: '';
        const tCost = cost ? '&cost=' + cost: '';
        const tPurpose = purpose ? '&purpose=' + purpose.join(',') : '';
        const tRentText = rentText ? '&rent_text=' + rentText.join(',') : '';

        navigate(`/map?region=${region.value}${tRisk}${tId}${tTypeOrendar}${tRentRate}${tSize}${tCost}${tPurpose}${tRentText}`);

        setTimeout(() => {
            this.getUrlParams();
        }, 100);
    }

    backToFullList() {
        this.getUrlParams();
    }

    componentDidMount() {
        this.getUrlParams();
    }

    getFiltersValue(from, to, type) {
        console.log(from, to, type)
    }


    componentDidUpdate() {
        window.addEventListener('resize', () => {
            this.mPanel.current.style.top = window.innerHeight + 'px';
        });
        this.mPanel.current.style.top = window.innerHeight + 'px';
    }

    render() {
        return(
            <div className="view-map">
                <div className="view-map__wrapper">
                    <MapAside 
                        params={this.state.urlParams}
                        objects={this.state.objects}
                        changeParams={this.changeParams}
                        emptyResponse={this.state.emptyResponse}
                        totalPages={this.state.totalPages}
                        page={this.state.page}
                        pagination={this.paginationObjects}
                        loadObjects={this.loadObjects}
                        isSingle={this.state.isSingle}
                        backToFullList={this.backToFullList}
                        filters={this.state.filters}
                    />
                    <MapInterface 
                        className={this.state.mPanel ? 'is-active' : ''}
                        points={this.state.points}
                        changeParams={this.changeParams}
                        loadObject={this.loadObject}
                    />

                    <div 
                        ref={this.mPanel}
                        className="view-map__m-panel button button--primary"
                        onClick={() => this.setState((state) => ({mPanel: !state.mPanel}))}
                    >
                        {!this.state.mPanel ? 'Карта' : 'Приховати'}
                    </div>
                </div>
            </div>
        );
    }
}