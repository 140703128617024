import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MapView from '../components/Map/MapView';

export default function Map({ data }) {
  return (
    <Layout
      headStyle="map"
      footerStyle="map"
    >
        <SEO title="Карта" />
        <Helmet>
          <link
            href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css"
            rel="stylesheet"
          />
        </Helmet>
        <MapView />
    </Layout>
  );
}
