import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import SelectM from '@material-ui/core/Select';
import CheckboxM from '@material-ui/core/Checkbox';
import MultGroup from '../Form/MultGroup';
import axios from 'axios';

export default class MapAsideForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            areas: [
                { label: "Вся Україна", value: "all" },
                { label: "Київ", value: "Київ" },
                { label: "Севастополь", value: "Севастополь" },
                { label: "Вінницька", value: "Вінницька" },
                { label: "Волинська", value: "Волинська" },
                { label: "Дніпропетровська", value: "Дніпропетровська" },
                { label: "Донецька", value: "Донецька" },
                { label: "Житомирська", value: "Житомирська" },
                { label: "Закарпатська", value: "Закарпатська" },
                { label: "Запорізька", value: "Запорізька" },
                { label: "Івано-Франківська", value: "Івано-Франківська" },
                { label: "Київська", value: "Київська" },
                { label: "Кіровоградська", value: "Кіровоградська" },
                { label: "Луганська", value: "Луганська" },
                { label: "Львівська", value: "Львівська" },
                { label: "Миколаївська", value: "Миколаївська" },
                { label: "Одеська", value: "Одеська" },
                { label: "Полтавська", value: "Полтавська" },
                { label: "Рівненська", value: "Рівненська" },
                { label: "Сумська", value: "Сумська" },
                { label: "Тернопільська", value: "Тернопільська" },
                { label: "Харківська", value: "Харківська" },
                { label: "Херсонська", value: "Херсонська" },
                { label: "Хмельницька", value: "Хмельницька" },
                { label: "Черкаська", value: "Черкаська" },
                { label: "Чернівецька", value: "Чернівецька" },
                { label: "Чернігівська", value: "Чернігівська" },
            ],
            filters: [],
            typeOrendar: null,
            rentText: null,
            rentRate: {
                from: null,
                to: null,
            },
            object_size: {
                from: null,
                to: null,
            },
            cost: {
                from: null,
                to: null,
            },
            selectedArea: null,
            selectedFilters: null,
            selectedTypeOrendar: null,
            selectedRentText: null,
            activeAreaIndex: 0,
            typing: false,
            typingTimeout: 0,
            toggler: false
        }

        this.hiddenPath = React.createRef();

        this.updateArea = this.updateArea.bind(this);
        this.selectFilters = this.selectFilters.bind(this);
        this.selectTypeOrendar = this.selectTypeOrendar.bind(this);
        this.selectRentText = this.selectRentText.bind(this);
        this.updateRentRate = this.updateRentRate.bind(this);
        this.updateCost = this.updateCost.bind(this);
        this.updateSize = this.updateSize.bind(this);
    }

    updateArea(event) {
        const value = event.target.value;

        if (value) {

            this.setState({
                selectedArea: value
            }, () => {

                this.props.changeParams({
                    region: value,
                    risks: this.state.selectedFilters && this.state.selectedFilters.map(i => i.value),
                    typeOrendar: this.state.selectedTypeOrendar && this.state.selectedTypeOrendar.map(i => i.value),
                    rentText: this.state.selectedRentText && this.state.selectedRentText.map(i => i.value),
                    rentRate: this.state.rentRate.from && `${this.state.rentRate.from}-${this.state.rentRate.to}`,
                    cost: this.state.cost.from && `${this.state.cost.from}-${this.state.cost.to}`,
                    object_size: this.state.object_size.from && `${this.state.object_size.from}-${this.state.object_size.to}`,
                });
            })
        }

    }

    selectFilters(event) {
        const value = event.target.value;

        if (value) {

            this.setState({
                selectedFilters: value, 
            }, () => {

                this.props.changeParams({
                    region: this.state.selectedArea,
                    risks: value.map(i => i.value),
                    typeOrendar: this.state.selectedTypeOrendar && this.state.selectedTypeOrendar.map(i => i.value),
                    rentText: this.state.selectedRentText && this.state.selectedRentText.map(i => i.value),
                    rentRate: this.state.rentRate.from && `${this.state.rentRate.from}-${this.state.rentRate.to}`,
                    cost: this.state.cost.from && `${this.state.cost.from}-${this.state.cost.to}`,
                    object_size: this.state.object_size.from && `${this.state.object_size.from}-${this.state.object_size.to}`,
                });
            });
        }
    }

    selectTypeOrendar(event) {
        const value = event.target.value;
        if (value) {

            this.setState({
                selectedTypeOrendar: value, 
            }, () => {

                this.props.changeParams({
                    region: this.state.selectedArea,
                    risks: this.state.selectedFilters && this.state.selectedFilters.map(i => i.value),
                    rentText: this.state.selectedRentText && this.state.selectedRentText.map(i => i.value),
                    typeOrendar: value.map(i => i.value),
                    rentRate: this.state.rentRate.from && `${this.state.rentRate.from}-${this.state.rentRate.to}`,
                    cost: this.state.cost.from && `${this.state.cost.from}-${this.state.cost.to}`,
                    object_size: this.state.object_size.from && `${this.state.object_size.from}-${this.state.object_size.to}`,
                });
            });
        }
    }

    selectRentText(event) {
        const value = event.target.value;
        if (value) {

            this.setState({
                selectedRentText: value, 
            }, () => {

                this.props.changeParams({
                    region: this.state.selectedArea,
                    risks: this.state.selectedFilters && this.state.selectedFilters.map(i => i.value),
                    typeOrendar: this.state.selectedTypeOrendar && this.state.selectedTypeOrendar.map(i => i.value),
                    rentText: value.map(i => i.value),
                    rentRate: this.state.rentRate.from && `${this.state.rentRate.from}-${this.state.rentRate.to}`,
                    cost: this.state.cost.from && `${this.state.cost.from}-${this.state.cost.to}`,
                    object_size: this.state.object_size.from && `${this.state.object_size.from}-${this.state.object_size.to}`,
                });
            });
        }
    }

    updateRentRate(from, to) {
        if (from && !to) to = 100;
        if (to && !from) from = 1;
        this.setState({
            rentRate: {
                from: from,
                to: to
            }
        }, () => {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typing: false,
                typingTimeout: setTimeout(() => {
                    this.props.changeParams({
                        region: this.state.selectedArea,
                        risks: this.state.selectedFilters && this.state.selectedFilters.map(i => i.value),
                        typeOrendar: this.state.typeOrendar && this.state.typeOrendar.map(i => i.value),
                        rentRate: from ? `${from}-${to}` : null,
                        rentText: this.state.selectedRentText && this.state.selectedRentText.map(i => i.value),
                        cost: this.state.cost.from && `${this.state.cost.from}-${this.state.cost.to}`,
                        object_size: this.state.object_size.from && `${this.state.object_size.from}-${this.state.object_size.to}`,
                    });
                }, 1000)
            });
        })
    }

    updateCost(from, to) {
        if (from && !to) to = 350000000;
        if (to && !from) from = 1;
        this.setState({
            cost: {
                from: from,
                to: to
            }
        }, () => {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typing: false,
                typingTimeout: setTimeout(() => {
                    this.props.changeParams({
                        region: this.state.selectedArea,
                        risks: this.state.selectedFilters && this.state.selectedFilters.map(i => i.value),
                        typeOrendar: this.state.typeOrendar && this.state.typeOrendar.map(i => i.value),
                        cost: from ? `${from}-${to}` : null,
                        rentText: this.state.selectedRentText && this.state.selectedRentText.map(i => i.value),
                        rentRate: this.state.rentRate.from && `${this.state.rentRate.from}-${this.state.rentRate.to}`,
                        object_size: this.state.object_size.from && `${this.state.object_size.from}-${this.state.object_size.to}`,
                    });
                }, 1000)
            });
        })
    }

    updateSize(from, to) {
        if (from && !to) to = 150000000;
        if (to && !from) from = 1;
        this.setState({
            object_size: {
                from: from,
                to: to
            }
        }, () => {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typing: false,
                typingTimeout: setTimeout(() => {
                    this.props.changeParams({
                        region: this.state.selectedArea,
                        risks: this.state.selectedFilters && this.state.selectedFilters.map(i => i.value),
                        typeOrendar: this.state.typeOrendar && this.state.typeOrendar.map(i => i.value),
                        object_size: from ? `${from}-${to}` : null,
                        rentText: this.state.selectedRentText && this.state.selectedRentText.map(i => i.value),
                        rentRate: this.state.rentRate.from && `${this.state.rentRate.from}-${this.state.rentRate.to}`,
                        cost: this.state.cost.from && `${this.state.cost.from}-${this.state.cost.to}`,
                    });
                }, 1000)
            });
        })
    }

    componentDidMount() {

        axios.get(`${process.env.API_ENDPOINT}/estate/dicts/entity_type`)
            .then((response) => {

                this.setState({
                    typeOrendar: Object.keys(response.data).map((key, index) => {
                        return {
                            title: response.data[key],
                            value: key
                        };
                    })
                }, () => {
                    this.setState((state, props) => ({
                        selectedTypeOrendar: props.params.typeOrendar 
                            && props.params.typeOrendar.map((item) => {
                                return state.typeOrendar.find(filter => filter.value === item);
                            }),
                    }));
                });
            })
            .catch((error) => {
                console.error(error);
            });

        axios.get(`${process.env.API_ENDPOINT}/estate/dicts/rent_law_share_text`)
            .then((response) => {
                this.setState({
                    rentText: response.data.map(item => {
                        return {
                            title: item.name,
                            value: item.id
                        };
                    })
                }, () => {
                    this.setState((state, props) => ({
                        selectedRentText: props.params.rentText 
                            && props.params.rentText.map((item) => {
                                return state.rentText.find(filter => filter.value == item);
                            }),
                    }));
                });
            })
            .catch((error) => {
                console.error(error);
            });

        this.setState((state, props) => ({
            filters: props.filters,
            selectedArea: props.params.region 
                ? state.areas.find(area => area.value === props.params.region)
                : state.areas.find(area => area.value === 'Київська'),
            selectedFilters: props.params.risks 
                && props.params.risks.map((item) => {
                    return props.filters.find(filter => filter.value === item);
                }),
            object_size: {
                from: props.params.object_size && props.params.object_size.split('-')[0],
                to: props.params.object_size && props.params.object_size.split('-')[1],
            },
            cost: {
                from: props.params.cost && props.params.cost.split('-')[0],
                to: props.params.cost && props.params.cost.split('-')[1],
            },
            rentRate: {
                from: props.params.rentRate && props.params.rentRate.split('-')[0],
                to: props.params.rentRate && props.params.rentRate.split('-')[1],
            },
        }));
    }

    render() {
        return (
            <div className="view-map__filter view-form">
                <div className="view-map__filter__header">
                    <FormControl className="view-form__group">
                        <InputLabel id="region-label" className="MuiFormLabel-filled">Оберіть регіон</InputLabel>
                        <SelectM
                            labelId="region-label"
                            id="region"
                            value={this.state.selectedArea}
                            onChange={this.updateArea}
                            renderValue={selected => selected.label}
                            input={<Input />}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                }
                            }}
                        >
                        {this.state.areas.map((area) => (
                            <MenuItem className="view-form__select__item--default" key={area.value} value={area}>
                                <ListItemText primary={area.label} />
                            </MenuItem>
                        ))}
                        </SelectM>
                    </FormControl>
                </div>
                <FormControl className="view-form__group">
                    <InputLabel id="demo-mutiple-name-label">Ризик фактори</InputLabel>
                    <SelectM
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        multiple
                        value={this.state.selectedFilters ? this.state.selectedFilters : []}
                        renderValue={selected =>
                            selected.map(item => item.title).join(', ')
                        }
                        onChange={this.selectFilters}
                        input={<Input />}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            }
                        }}
                    >
                    {this.state.filters.map((filter) => (
                        <MenuItem key={filter.value} value={filter}>
                            <CheckboxM checked={this.state.selectedFilters && this.state.selectedFilters.find(select => select === filter.value)} />
                            <ListItemText primary={filter.title} />
                        </MenuItem>
                    ))}
                    </SelectM>
                </FormControl>
                <button 
                    className="button button--default" 
                    onClick={() => {
                        this.hiddenPath.current.classList.toggle('is-active');
                        this.setState((state) => ({
                            toggler: !state.toggler
                        }))
                    }}
                >
                    {this.state.toggler ? 'Менше фільтрів' : 'Більше фільтрів'}
                </button>

                <div className="view-map__filter__hidden" ref={this.hiddenPath}>
                    <MultGroup 
                        label="Орендна ставка"
                        pre="%"
                        getValues={this.updateRentRate}
                        from={this.state.rentRate.from}
                        to={this.state.rentRate.to}
                        min="1"
                        max="100"
                        type="rent_rate"
                    />
                    <MultGroup 
                        label="Розмір майна"
                        pre=""
                        getValues={this.updateSize}
                        from={this.state.object_size.from}
                        to={this.state.object_size.to}
                        min="1"
                        max="150000000"
                        type="object_size"
                    />
                    <MultGroup 
                        label="Ціна майна"
                        pre="грн"
                        getValues={this.updateCost}
                        from={this.state.cost.from}
                        to={this.state.cost.to}
                        min="1"
                        max="350000000"
                        type="cost"
                    />
                    {this.state.rentText && 
                        <FormControl className="view-form__group">
                            <InputLabel id="demoq-mutiple-name-label">Цільове призначення</InputLabel>
                            <SelectM
                                labelId="demoq-mutiple-name-label"
                                id="demoq-mutiple-name"
                                multiple
                                value={this.state.selectedRentText ? this.state.selectedRentText : []}
                                renderValue={selected =>
                                    selected.map(item => item.title).join(', ')
                                }
                                onChange={this.selectRentText}
                                input={<Input />}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                    }
                                }}
                            >
                            {this.state.rentText.map((filter) => (
                                <MenuItem key={filter.value} value={filter}>
                                    <CheckboxM checked={this.state.selectedRentText && this.state.selectedRentText.find(select => select === filter.value)} />
                                    <ListItemText primary={filter.title} />
                                </MenuItem>
                            ))}
                            </SelectM>
                        </FormControl>
                    }
                    {this.state.typeOrendar && 
                        <FormControl className="view-form__group">
                            <InputLabel id="orendar-mutiple-name-label">Тип орендаря</InputLabel>
                            <SelectM
                                labelId="orendar-mutiple-name-label"
                                id="orendar-mutiple-name"
                                multiple
                                value={this.state.selectedTypeOrendar ? this.state.selectedTypeOrendar : []}
                                renderValue={selected =>
                                    selected.map(item => item.title).join(', ')
                                }
                                onChange={this.selectTypeOrendar}
                                input={<Input />}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                    }
                                }}
                            >
                            {this.state.typeOrendar && this.state.typeOrendar.map((filter) => (
                                <MenuItem key={filter.value} value={filter}>
                                    <CheckboxM checked={this.state.selectedTypeOrendar && this.state.selectedTypeOrendar.find(select => select == filter.value)} />
                                    <ListItemText primary={filter.title} />
                                </MenuItem>
                            ))}
                            </SelectM>
                        </FormControl>
                    }
                </div>
            </div>
        );
    }
}
