import React, { useRef, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

export default function MultGroup(props) {
    const [fromValue, setFromValue] = useState(null);
    const [toValue, setToValue] = useState(null);
    const pre = props.pre;
    const label = props.label;
    const min = props.min ? props.min : null;
    const max = props.max ? props.max : null;
    const type = props.type;
    const item = useRef();

    const updateValue = (event, type) => {
        let value = event.target.value;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        if (type === 'from') {
            if (toValue && value > toValue) setToValue(value);
            !toValue && setToValue(value);

            setFromValue(value);
            props.getValues(value, toValue);
        }
        if (type === 'to') {
            if (fromValue && value < fromValue) setFromValue(value);
            !fromValue && setFromValue(value);
            setToValue(value);
            props.getValues(fromValue, value);
        }
    }

    const sendValue = (event, type) => {
        let value = event.target.value;
        let from = fromValue;
        let to = toValue;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        if (type === 'from') {
            setFromValue(value);
            if (value > toValue) to = value;
            setToValue(to);

            props.getValues(value, to);
        }
        if (type === 'to') {
            setToValue(value);
            if (value < fromValue) from = value;
            setFromValue(from);

            props.getValues(from, value);
        }
    }

    const clear = (event) => {
        setToValue('');
        setFromValue('');
        props.getValues('', '');
        const element = event.target;
        const classToBeRemove ='MuiFormLabel-filled';
        const labels = element.closest('.view-form__multgroup').querySelectorAll('label');
        labels.forEach((item)=>{
            item.classList.remove(classToBeRemove);
        })
    }

    useEffect(() => {
        setFromValue(props.from);
        setToValue(props.to);

        document.body.addEventListener('click', event => {
            if (!document.querySelector('.view-form__multgroup')) return;
            const element = event.target;

            if (element != item.current) {

                if (!element.closest('.view-form__multgroup') || element.closest('.view-form__multgroup') != item.current) {
                    item.current.classList.remove('is-active');
                }
            }
        });
    })

    return (
        <div className="view-form__group">
            <div className="view-form__multgroup" ref={item}>
                <div 
                    className={`view-form__multgroup__label ${(fromValue || toValue) ? 'has-value' : ''}`}
                    onClick={() => {item.current.classList.toggle('is-active');}}
                    data-id={type}
                >
                    <span>{label}</span>
                    <b>
                        {fromValue && `від ${fromValue} `}
                        {toValue && `до ${toValue} `}
                        {(fromValue || toValue && pre) && pre}
                    </b>
                    {(fromValue || toValue) && <i onClick={(event) => clear(event)}>×</i>}
                    <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
                </div>

                <div className="view-form__multgroup__menu">
                    <TextField label="Від" type="number" className={fromValue && 'has-value'} value={fromValue} onBlur={(event) => sendValue(event, 'from')} onChange={(event) => updateValue(event, 'from')}/>
                    <TextField label="До" type="number" className={toValue && 'has-value'} value={toValue} onBlur={(event) => sendValue(event, 'to')} onChange={(event) => updateValue(event, 'to')}/>
                </div>
            </div>
        </div>
    );
}